@import '../variables.scss';

.application-page{
    padding-block: 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .success-message {
        color: green;
        font-weight: bold;
        margin-top: 10px;
      }
      
      .error-message {
        color: red;
        font-weight: bold;
        margin-top: 10px;
      }
      

    .text{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title{
            color: #F7941D;
        }
        .subtitle{
            width: 50%;
        }
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 24px;
        .half-input{
            display: flex;
            gap: 12px;
            .form-group{
                display: flex;
                flex-direction: column;
                gap: 12px;
                width: 100%;

                .label-icon{
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    .icon{
                        color: $secondaryColor;
                        font-size: 12px;
                    }
                }
                .input-icon-wrapper {
                    position: relative;
                    width: 100%;
                  }
                  
                  .icon-file {
                    position: absolute;
                    top: 50%;
                    right: 10px; 
                    transform: translateY(-50%) rotate(225deg);
                    cursor: pointer; 
                    color: $primaryColor;
                  }
                  
                  
            }

            
           
        }
    }
}
@media screen and (max-width: 1200px){
    .application-page{
        .text{
           
            .subtitle{
                width: 100%;
            }
        }
    }
    
}

@media screen and (max-width: 550px){
    .application-page{
        .text{
           
            .subtitle{
                width: 100%;
            }
        }
        form{
            .half-input{
                flex-direction: column;
                .form-group{
                    .input-icon-wrapper {
                        width: 100%;
                      }
                }
            }
           
        }
    }
    
}