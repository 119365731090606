.resources-page {
  padding-block: 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.resources-page .work-force {
  background-color: rgb(253, 234, 210);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.resources-page .work-force a {
  text-decoration: underline;
}
.resources-page .contact-number {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.resources-page .contact-number .contact-paylocity {
  display: flex;
  gap: 24px;
}
.resources-page .contact-number .contact-paylocity .employee-contacts {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.resources-page .contact-number .contact-paylocity .employee-contacts .contact {
  background-color: rgb(253, 234, 210);
  padding: 40px;
  border-radius: 5px;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.resources-page .contact-number .contact-paylocity .paylocity {
  background-color: rgb(253, 234, 210);
  padding: 40px;
  border-radius: 5px;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}
.resources-page .contact-number .contact-paylocity .paylocity a {
  text-decoration: underline;
}
.resources-page .employee-resources {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(1, 69, 95, 0.2);
  width: 50%;
  border-radius: 5px;
  margin: auto;
  padding: 20px;
}
.resources-page .employee-resources .policies {
  background-color: #01455F;
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 10px;
}
.resources-page .employee-resources .policies ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: disc;
  color: white;
  font-size: 24px;
}
.resources-page .FQA {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  background-color: rgb(255, 250, 244);
}
.resources-page .FQA h1 {
  color: #F7941D;
}
.resources-page .FQA .questions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.resources-page .FQA .questions .question {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.resources-page .FQA .questions .question .question-icon {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .resources-page .contact-number .contact-paylocity {
    flex-direction: column;
  }
  .resources-page .contact-number .contact-paylocity .employee-contacts .contact {
    width: 40%;
  }
  .resources-page .contact-number .contact-paylocity .paylocity {
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .resources-page .contact-number .contact-paylocity {
    flex-direction: column;
  }
  .resources-page .contact-number .contact-paylocity .employee-contacts .contact {
    width: 100%;
  }
  .resources-page .contact-number .contact-paylocity .paylocity {
    width: 100%;
  }
  .resources-page .employee-resources {
    width: 100%;
  }
}
@media screen and (max-width: 360px) {
  .container .contents {
    padding-inline: 4px;
  }
  .container .resources-page .work-force a {
    font-size: 12px;
  }
}/*# sourceMappingURL=employees-resources.css.map */