.about-us .page-header {
  position: relative;
}
.about-us .page-header img {
  width: 100%;
  height: 60vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-us .page-header .page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 100px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.64);
  height: 60vh;
  inset: 0;
}
.about-us .page-header .page-content .page-text {
  gap: 24px;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .about-us .page-header .page-content {
    padding-inline: 20px;
  }
}/*# sourceMappingURL=pageHeader.css.map */