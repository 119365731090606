.container .contacts {
  background-color: #F7941D;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 100px;
  color: #01455F;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
}
.container .contacts .contact-info {
  display: flex;
  gap: 24px;
}
.container .contacts .contact-info .location a, .container .contacts .contact-info .email a, .container .contacts .contact-info .phone a {
  display: flex;
  gap: 12px;
  align-items: center;
}
.container .contacts .contact-info div {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1000px) {
  .container .contacts {
    padding: 24px 32px;
  }
}
@media screen and (max-width: 930px) {
  .container .contacts {
    padding: 24px 20px;
  }
  .container .contacts .contact-info div span {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .container .contacts {
    padding: 24px 40px;
  }
}/*# sourceMappingURL=top-nav.css.map */