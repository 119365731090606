@import '../variables.scss';
.contact-us{
    .contact-page{
        display: flex;
        flex-direction: column;
        gap: 60px;
        padding-block: 42px;
        
        .contact-address{
            display: grid;
            grid-template-columns: 28% 66%;
            gap: 70px;
            .address-cards{
                display: flex;
                flex-direction: column;
                gap: 40px;
    
                p,h3{
                    color: white;
                }
                .icon{
                    color: $secondaryColor;
                }
    
                .card{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    align-items: center;
                    border-radius: 10px;
                    background-color: $primaryColor;
                    padding: 60px 20px;
                    .icon-text{
                        display: flex;
                        gap: 24px;
                        align-items: center;
                    }
                }
    
            }
            .contact-form{
                form{
                   display: flex;
                   flex-direction: column;
                   gap: 12px;
                   .form-group{
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    .orange-btn{
                        width: fit-content;
                    }
                }
                    .half-input{
                        .form-group{
                            display: flex;
                            flex-direction: column;
                            gap: 12px;
                            width: 100%;
                        }
                        display: flex;
                        gap: 12px;
                    }
                }
               
            }
        }
    }
}


@media screen and (max-width: 810px){
    .contact-us{
        .contact-page{
            .contact-address{
                display: grid;
                grid-template-columns: 100%;
            }
        }
    }
    
}