.application-page {
  padding-block: 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.application-page .success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}
.application-page .error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
.application-page .text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.application-page .text .title {
  color: #F7941D;
}
.application-page .text .subtitle {
  width: 50%;
}
.application-page form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.application-page form .half-input {
  display: flex;
  gap: 12px;
}
.application-page form .half-input .form-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
.application-page form .half-input .form-group .label-icon {
  display: flex;
  gap: 12px;
  align-items: center;
}
.application-page form .half-input .form-group .label-icon .icon {
  color: #F7941D;
  font-size: 12px;
}
.application-page form .half-input .form-group .input-icon-wrapper {
  position: relative;
  width: 100%;
}
.application-page form .half-input .form-group .icon-file {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%) rotate(225deg);
  cursor: pointer;
  color: #01455F;
}

@media screen and (max-width: 1200px) {
  .application-page .text .subtitle {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .application-page .text .subtitle {
    width: 100%;
  }
  .application-page form .half-input {
    flex-direction: column;
  }
  .application-page form .half-input .form-group .input-icon-wrapper {
    width: 100%;
  }
}/*# sourceMappingURL=application.css.map */