.home-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 24px;
}
.home-container h3 {
  color: #01455f;
}
.home-container .hero-section {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
  padding-inline: 100px;
}
.home-container .hero-section .home-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.home-container .hero-section .home-text ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: circle;
  list-style: inside;
  color: #01455f;
}
.home-container .hero-section img {
  width: 50vw;
  height: 50vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-container .why-cohesive {
  display: flex;
  background-color: rgba(247, 148, 29, 0.05);
}
.home-container .why-cohesive .text-img {
  padding: 100px;
  display: flex;
  gap: 48px;
  width: 100%;
}
.home-container .why-cohesive .text-img img {
  height: 50vh;
  width: 80%;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-container .why-cohesive .text-img .text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.home-container .our-employees {
  display: flex;
  gap: 24px;
  padding-inline: 100px;
}
.home-container .our-employees .employee-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.home-container .our-employees .employee-text ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: circle;
  list-style: inside;
  color: #01455f;
}
.home-container .our-employees img {
  width: 50%;
  height: 50%;
}
.home-container .our-partners {
  background-color: rgba(247, 148, 29, 0.05);
  padding: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.home-container .our-partners .partners {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 24px;
}
.home-container .our-partners .partners .partner {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.home-container .our-partners .partners .partner img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px 10px 0 0;
}
.home-container .our-partners .partners .partner .partner-details {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.home-container .our-partners .partners .partner .partner-details .icon {
  font-size: 24px;
  color: #f7941d;
}
.home-container .our-partners .partners .partner .partner-details a {
  text-decoration: underline;
  color: #f7941d;
}
.home-container .our-partners .partners .partner .partner-details .partner-location {
  display: flex;
  gap: 12px;
  align-items: center;
}
.home-container .our-partners .partners .partner .partner-details .partner-phone {
  display: flex;
  gap: 12px;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .home-container .hero-section {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    padding-inline: 20px;
  }
  .home-container .hero-section img {
    width: 100%;
    height: 80%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 20px;
  }
  .home-container .why-cohesive {
    display: block;
  }
  .home-container .why-cohesive .text-img {
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
    gap: 24px;
    width: 100%;
  }
  .home-container .why-cohesive .text-img img {
    height: 80%;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 20px;
  }
  .home-container .our-employees {
    flex-direction: column-reverse;
    padding-inline: 20px;
  }
  .home-container .our-employees img {
    width: 100%;
    height: 80%;
    border-radius: 20px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .home-container .our-partners {
    padding-inline: 20px;
  }
  .home-container .our-partners .partners {
    display: grid;
    grid-template-columns: 49% 49%;
  }
}
@media screen and (max-width: 550px) {
  .home-container .our-partners {
    padding-inline: 20px;
  }
  .home-container .our-partners .partners {
    display: grid;
    grid-template-columns: 100%;
  }
}/*# sourceMappingURL=home.css.map */