@import '../variables.scss';

.for-employees{
    display: flex;
    flex-direction: column;
    .employees{
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding-block: 48px;

        .introduction-text{
            font-size: 24px;
        }
        .application{
            background-color: $lighterBlue;
            padding: 60px;
            border-radius: 10px;
            display: grid;
            grid-template-columns: 30% 60%;
            justify-content: space-between;
            align-items: center;

            .title{
                color: $primaryColor;
                font-weight: bold;
            }
            .first-applicant{
display: flex;
flex-direction: column;
gap:12px;

.application-btn{
    border: 1px solid $primaryColor;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    span{
        color: $secondaryColor;
    }
}
            }
            .contact{
                display: flex;
                flex-direction: column;
                gap: 12px;

                .contact-btn{
                    border: 1px solid $primaryColor;
                    width: fit-content;
                    padding: 10px;
                    border-radius: 10px; 
                }
            }
        }
        .job-opportunities{
            display: flex;
            flex-direction: column;
            gap: 24px;

            .jobs{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 24px;
                .job{
                    background-color:$lighterBlue;
                    padding: 24px;
                    border-radius: 10px;
                    display: flex;
                        flex-direction: column;
                        gap: 10px;

                    ul{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    }
                    .apply{
                        color: $secondaryColor;
                        text-decoration: underline;
                    }


                    .job-title{
                        color: $primaryColor;
                        font-weight: bold;
                    }
                }
            }
        }
        .notes{
            color: $primaryColor;
            display: flex;
            gap: 12px;
            flex-direction: column;
        }
        .employees-benefits{
            display: flex;
            flex-direction: column;
            gap: 12px;
            .cards{
                display: flex;
                flex-wrap: wrap;
                gap: 24px;

                .card{
                    border: 1px solid rgba(1, 69, 95, 0.1);
                    border-radius: 10px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    align-items: center;
                    width: 32%;

                    .icon{
                        background-color: $primaryColor;
                        padding: 20px;
                        border-radius: 10px;
                        color: white;

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .for-employees{
        .employees{
            .application{
                grid-template-columns: 100%;
                gap: 48px;
            }
            .job-opportunities{
                .jobs{
                    grid-template-columns: 1fr;
                }
              
            }
            .employees-benefits{
                .cards{
                    display: grid;
                    grid-template-columns: 50% 50%;

                    .card{
                        width: 90%;
                    }
                }
            }
        }
    } 
}

@media screen and (max-width: 360px) {
    .for-employees {
        .employees {
            .employees-benefits {
                .cards {
                    display: grid;
                    grid-template-columns: 100%;
                  }
            }
        }
    }
  
  }