.team-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 42px;
}
.team-container .team-members {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 24px;
}
.team-container .team-members .team-member {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  border-radius: 10px;
}
.team-container .team-members .team-member:hover .card-overlay {
  top: 0;
  transition: 0.5s ease;
}
.team-container .team-members .team-member .card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  left: 0;
  top: 700px;
  bottom: 0;
  background: #01455F;
  padding: 20px;
  transition: 0.5s ease;
}
.team-container .team-members .team-member .card-overlay p, .team-container .team-members .team-member .card-overlay .title-overlay, .team-container .team-members .team-member .card-overlay h3 {
  color: white;
}
.team-container .team-members .team-member .team-member-image {
  width: 100%;
}
.team-container .team-members .team-member .team-member-image img {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.team-container .team-members .team-member .team-member-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.team-container .team-members .team-member .team-member-info .title {
  color: #F7941D;
  font-weight: 400;
}

@media screen and (max-width: 1000px) {
  .team-container .team-members {
    grid-template-columns: 50% 50%;
  }
  .team-container .team-members .team-member .team-member-image img {
    width: 95%;
  }
}
@media screen and (max-width: 680px) {
  .team-container .team-members {
    grid-template-columns: 100%;
  }
  .team-container .team-members .team-member .team-member-image img {
    width: 100%;
  }
}/*# sourceMappingURL=team.css.map */