@import "../variables";

.call-to-action {
  width: 70%;
  margin: auto;
  color: $primaryColor;

  h1 {
    font-size: 32px;
  }

  .call-to-action-text {
    display: flex;
    gap: 24px;
    background-color: rgba(0, 174, 239, 0.2);
    padding: 50px 80px 50px 80px;
    border-radius: 10px;

    .line {
      width: 3px;
      background-color: #01455f;
    }
    .contact-info {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      p {
        color: $primaryColor;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .call-to-action {
    width: 90%;
    .call-to-action-text {
      flex-direction: column;
      padding: 35px 20px 35px 20px;
      .line {
        height: 2px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 540px){
  .call-to-action h1 {
    font-size: 24px;
  }
  
}

@media screen and (max-width: 360px) {
  .call-to-action {
    width: 100%;
  }
}
