.hiring .hiring-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-block: 100px;
}
.hiring .hiring-page .expertise p, .hiring .hiring-page .expertise h3 {
  color: white;
}
.hiring .hiring-page .expertise .expertise-img {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
}
.hiring .hiring-page .expertise .expertise-img img {
  width: 100%;
  height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.hiring .hiring-page .expertise .expertise-img .exertise-list {
  background-color: #01455F;
  padding: 40px;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hiring .hiring-page .expertise .expertise-img .exertise-list .text-icon {
  display: flex;
  gap: 24px;
  align-items: center;
}
.hiring .hiring-page .expertise .expertise-img .exertise-list .text-icon .text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.hiring .hiring-page .expertise .expertise-img .exertise-list .text-icon .icon {
  color: #F7941D;
}
.hiring .hiring-page .employee-benefits {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #01455F;
}
.hiring .hiring-page .employee-benefits .text-section {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 100px;
  width: 100%;
}
.hiring .hiring-page .employee-benefits .text-section img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.hiring .hiring-page .employee-benefits .text-section .img-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hiring .hiring-page .employee-benefits .benefits {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.hiring .hiring-page .employee-benefits .benefits .benefit {
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 1000px) {
  .hiring .hiring-page .expertise .expertise-img {
    grid-template-columns: 48% 48%;
  }
  .hiring .hiring-page .employee-benefits .text-section {
    display: grid;
    grid-template-columns: 42% 42%;
    gap: 94px;
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .hiring .hiring-page .expertise .expertise-img {
    grid-template-columns: 100%;
  }
  .hiring .hiring-page .expertise .expertise-img img {
    height: 300px;
  }
  .hiring .hiring-page .employee-benefits .text-section {
    grid-template-columns: 100%;
  }
  .hiring .hiring-page .employee-benefits .benefits {
    grid-template-columns: 100%;
  }
}/*# sourceMappingURL=hiring.css.map */