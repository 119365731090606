.home-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-block: 24px;
  
    h3 {
      color: #01455f;
    }
  
    .hero-section {
      // display: flex;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 24px;
      padding-inline: 100px;
  
      .home-text {
        display: flex;
        flex-direction: column;
        gap: 24px;
  
        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          list-style-type: circle;
          list-style: inside;
          color: #01455f;
        }
      }
  
      img {
        width: 50vw;
        height: 50vh;
        object-fit: cover;
      }
    }
  
    .why-cohesive {
      display: flex;
  
      background-color: rgba(247, 148, 29, 0.05);
  
      .text-img {
        padding: 100px;
        display: flex;
        gap: 48px;
        width: 100%;
  
        img {
          height: 50vh;
          width: 80%;
          object-fit: cover;
        }
  
        .text {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;
        }
      }
    }
  
    .our-employees {
      display: flex;
      gap: 24px;
      padding-inline: 100px;
  
      .employee-text {
        display: flex;
        flex-direction: column;
        gap: 24px;
  
        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          list-style-type: circle;
          list-style: inside;
          color: #01455f;
        }
      }
  
      img {
        width: 50%;
        height: 50%;
      }
    }
  
    .our-partners {
      background-color: rgba(247, 148, 29, 0.05);
      padding: 100px;
      display: flex;
      flex-direction: column;
      gap: 30px;
  
      .partners {
        display: grid;
        grid-template-columns: 32% 32% 32%;
        gap: 24px;
        .partner {
          background-color: white;
          border-radius: 10px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
          }
          .partner-details {
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
  
            .icon {
              font-size: 24px;
              color: #f7941d;
            }
            a {
              text-decoration: underline;
              color: #f7941d;
            }
  
            .partner-location {
              display: flex;
              gap: 12px;
              align-items: center;
            }
            .partner-phone {
              display: flex;
              gap: 12px;
              align-items: center;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1000px) {
    .home-container {
      .hero-section {
        display: flex;
        width: 100%;
        flex-direction: column-reverse;
        padding-inline: 20px;
        img {
          width: 100%;
          height: 80%;
          object-fit: cover;
          border-radius: 20px;
        }
      }
  
      .why-cohesive {
        display: block;
  
        .text-img {
          display: flex;
          flex-direction: column;
          padding-inline: 20px;
          gap: 24px;
          width: 100%;
          img {
            height: 80%;
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
          }
        }
      }
  
      .our-employees {
        flex-direction: column-reverse;
        padding-inline: 20px;
        img {
          width: 100%;
          height: 80%;
          border-radius: 20px;
          object-fit: cover;
        }
      }
  
      .our-partners {
        padding-inline: 20px;
        .partners {
          display: grid;
          grid-template-columns: 49% 49%;
        }
      }
    }
  }
  
  @media screen and (max-width: 550px) {
    .home-container {
      .our-partners {
        padding-inline: 20px;
        .partners {
          display: grid;
          grid-template-columns: 100%;
        }
      }
    }
  }
  