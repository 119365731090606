@import '../variables.scss';

.for-employers {
    display: flex;
    flex-direction: column;

    .employers {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding-block: 48px;


        .services {
            display: grid;
            grid-template-columns: 40% 50%;
            gap: 100px;

            .text {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }

            .cards {
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 24px;


                .card {

                    color: #01455F;
                    font-size: 16px;


                    .yellow-background {
                        padding: 30px 50px;
                        background-color: rgba(247, 148, 29, 0.2);
                        border-radius: 10px;
                        font-weight: bold;
                        height: 90%;

                    }

                    .blue-background {
                        background-color: rgba(0, 174, 239, 0.2);
                        padding: 30px 50px;
                        border-radius: 10px;
                        font-weight: bold;
                        height: 90%;
                    }
                }
            }
        }

        .onboard-process {

            .process-img-text {
                display: grid;
                grid-template-columns: 54% 46%;
                gap: 72px;

                .process-text {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
            }

            img {
                width: 500px;
                height: 400px;
                border-radius: 10px;
                object-fit: cover;
            }
        }

        .benefits-list {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .cards {
                display: grid;
                grid-template-columns: 32% 32% 32%;
                gap: 24px;

                .card {
                    .yellow-background {
                        padding: 28px 18px;
                        background-color: rgba(247, 148, 29, 0.2);
                        border-radius: 10px;
                        height: 90%;

                    }

                    .blue-background {
                        background-color: rgba(0, 174, 239, 0.2);
                        padding: 28px 18px;
                        border-radius: 10px;
                        height: 90%;
                    }
                }
            }
        }


        .testimonials {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .testimonial-slider {
                overflow: hidden;

                .testimonial-wrapper {
                    display: grid;
                    transition: transform 0.5s ease;
                    gap: 24px;
                    grid-template-columns: 49% 49%;

                    .testimonial {
                        flex: 0 0 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 24px;
                        padding: 20px;
                        background-color: #d9f3fd;
                        border-radius: 10px;
                        text-align: center;
                    }

                    @media (min-width: 950px) {
                       

                        .testimonial {
                            flex: 0 0 calc(33.333% - 16px);
                            padding: 20px 60px;
                        }
                    }
                }
            }

            .testimonial-navigation {
                display: flex;
                justify-content: center;
                gap: 10px;

                .prev-button,
                .next-button {
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    padding: 10px 20px;
                    cursor: pointer;
                    border-radius: 5px;
                }

                .prev-button:disabled,
                .next-button:disabled {
                    background-color: #ccc;
                    cursor: not-allowed;
                }
            }
        }






    }
}

@media screen and (max-width: 1000px) {
    .for-employers {
        .employers {
            .services{
                .cards{
                    grid-template-columns: 48% 48%;
                }
            }
            .onboard-process {
                img{
                    width: 400px;
                }
                .process-img-text {
                    gap: 32px;
                  }
            }
        }
    }
    
    
   
    
}

@media screen and (max-width: 768px) {
    .for-employers {
        .employers {
            .services {
                grid-template-columns: 100%;

                .cards {
                    grid-template-columns: 48% 48%;
                }
            }

            .onboard-process {
                .process-img-text {
                    grid-template-columns: 100%;
                }
                img {
                    width: 100%;
                }
            }

            .benefits-list {
                .cards {
                    grid-template-columns: 100%;
                }
            }

            .testimonials {
                .testimonial-slider {
                    .testimonial-wrapper {
                        .testimonial {
                            padding: 20px 60px;
                        }
                    }
                }
            }
        }
    }

}
@media screen and (max-width: 540px) {
    .for-employers .employers .testimonials .testimonial-slider .testimonial-wrapper {
        display: grid;
        transition: transform 0.5s ease;
        gap: 24px;
        grid-template-columns: 100%;
    }
    
}
@media screen and (max-width: 360px) {
    .for-employers {
        .employers{
            .services{
                .cards {
                    grid-template-columns: 100%;
                  }
            }
            .onboard-process{
                img {
                    width: 100%;
                }
            }
        }
    }   
  
    
  }