.footer-menu{
    background-color: #01455F;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: white;
    .line{
        width: 100%;
        height: 1px;
        background-color: white;
        
    }

    .footer-menu-item{
        display: flex;
        justify-content: space-between;
        .footer-p{
            color:white;
        }

        .follow{
            display: flex;
            gap: 12px;
            .icon{
                color: white;
            }
        }
        
    }
    .apply-job{
        display: flex;
        gap: 12px;
        .apply{
            color: #F7941D;
        }
    }
    
}