.page-header-p {
  color: white;
  width: 50%;
  font-size: 24px;
}

.about-container {

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 42px;

  .about-contents {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 20px;

    .company-history {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 24px;
    }

    .values {
      padding: 40px 0 40px 0;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .values-img {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 24px;
        align-items: center;

        .values-list {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 24px;
          justify-content: center;

          .value {
            color: #01455f;
            font-size: 16px;
          }

          .yellow-background {
            padding: 18px;
            background-color: rgba(247, 148, 29, 0.2);

            border-radius: 10px;
          }

          .blue-background {
            background-color: rgba(0, 174, 239, 0.2);

            padding: 18px;
            border-radius: 10px;
          }
        }

        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }

  .mission {
    background-color: #01455f;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 12px;

    p {
      color: white;
    }

    h1 {
      color: #00aeef;
    }

    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }

    .text {
      padding: 100px;
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
  }

  .partners {
    padding-block: 50px;
    padding-inline: 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .partners-logo {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      
    }
  }
}

@media screen and (max-width: 1000px) {
  .page-header-p {
    color: white;
    width: 100%;
    font-size: 24px;
  }
  
}

@media screen and (max-width: 768px) {
  .about-container {
    .about-contents {
      .company-history {
        grid-template-columns: 100%;
      }

      .values {
        .values-img {
          grid-template-columns: 100%;

          .values-list {
            grid-template-columns: 100%;
          }
        }
      }
    }

    .mission {
      grid-template-columns: 100%;
      gap: 12px;

      .text {
        padding: 30px 20px;
      }
    }

    .partners {
      padding-inline: 20px;

    
    }
  }
  .page-header-p {
    color: white;
    width: 100%;
    font-size: 18px;
  }
}

@media screen and (max-width: 360px) {
  .about-container {
    .partners {
      .partners-logo {
        // display: grid;
        // grid-template-columns: 50% 50%;
        // gap: 24px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}