* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 55px;
}
.container .contents {
  padding-inline: 100px;
}

p, h3 {
  color: #01455F;
}

a {
  text-decoration: none;
  color: #01455F;
  font-size: 18px;
}

ul {
  list-style: none;
}

.dark-blue-title {
  font-size: 48px;
  color: #01455F;
}

.white-title {
  color: white;
  font-size: 48px;
}

.page-header-p {
  color: white;
}

.text-p {
  font-size: 18px;
  color: #01455F;
}

input, select, textarea {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #01455F;
  border-radius: 10px;
  outline: none;
  color: #01455F;
}

label {
  color: #01455F;
}

ul {
  color: #01455F;
}

.orange-btn {
  background-color: #F7941D;
  color: white;
  padding: 18px 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .container .contents {
    padding-inline: 20px;
  }
  .page-header .page-content .white-title {
    color: white;
    font-size: 32px;
  }
  .dark-blue-title {
    font-size: 32px;
    color: #01455F;
  }
}
@media screen and (max-width: 540px) {
  .page-header .page-content {
    padding-inline: 20px;
  }
  .page-header .page-content .white-title {
    color: white;
    font-size: 24px;
  }
  .dark-blue-title {
    font-size: 24px;
    color: #01455F;
  }
}/*# sourceMappingURL=main.css.map */