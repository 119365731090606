@import "../variables";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  color: #01455f;
  background-color: white;
  position: fixed;
  top: 68px;
  width: 100%;
  z-index: 1000;

  .close-icon,
  .menu-bar {
    display: none;
  }

  .logo {
    img {
      width: 100px;
      height: 50px;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    gap: 24px;

    .team {
      display: none;
    }

    .dropdown-menu {
      display: none;
    }

    li .active {
      font-weight: bold;
    }
  }

  .team-btn {
    padding: 12px 24px;
    border: 1px solid #00aeef;
    background-color: white;
    border-radius: 10px;
    color: #01455f;
  }

  .dropdown {
    position: relative;

    .dropdown-header {
      display: flex;
      align-items: center;
      cursor: pointer;

      .dropdown-icon {
        margin-left: 5px;
        transition: transform 0.3s;
      }

      &.open .dropdown-icon {
        transform: rotate(180deg);
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      list-style: none;
      padding: 0;
      margin: 0;
      display: none;

      li {
        margin: 0;

        a {
          padding: 10px 8px;
          display: block;
          white-space: nowrap;
        }
      }
    }

    &.open .dropdown-menu {
      display: block;
    }
  }
}

@media screen and (max-width: 1000px) {
  .header {
    .close-icon,
    .menu-bar {
      display: block;
      width: 24px;
      height: auto;
    }
    .menu{
      .team{
        display: none;
      }
    }
    .team {
      .team-btn {
        display: flex;
      }
    }

    .nav {
      flex-direction: column;
      align-items: flex-end;
      gap: 40px;
      display: none;
      position: absolute;
      top: 80px;
      right: 0;
      background-color: #00AEEF;
      width: 100%;
      padding: 40px;
      height: 100vh;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

      .team {
        display: flex;
        background-color: white;
        border-radius: 10px;
      }
    }

    .nav.open {
      display: flex;
    }

    .menu {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .dropdown {
      .open {
        .dropdown-menu {
          background-color: #00AEEF;
        }
      }

      .dropdown-menu {
        li {
          a {
            background-color: #00AEEF;
          }
        }
      }
    }
  }
}
