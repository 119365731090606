.call-to-action {
  width: 70%;
  margin: auto;
  color: #01455F;
}
.call-to-action h1 {
  font-size: 32px;
}
.call-to-action .call-to-action-text {
  display: flex;
  gap: 24px;
  background-color: rgba(0, 174, 239, 0.2);
  padding: 50px 80px 50px 80px;
  border-radius: 10px;
}
.call-to-action .call-to-action-text .line {
  width: 3px;
  background-color: #01455f;
}
.call-to-action .call-to-action-text .contact-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.call-to-action .call-to-action-text .contact-info p {
  color: #01455F;
}

@media screen and (max-width: 950px) {
  .call-to-action {
    width: 90%;
  }
  .call-to-action .call-to-action-text {
    flex-direction: column;
    padding: 35px 20px 35px 20px;
  }
  .call-to-action .call-to-action-text .line {
    height: 2px;
    width: 100%;
  }
}
@media screen and (max-width: 540px) {
  .call-to-action h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 360px) {
  .call-to-action {
    width: 100%;
  }
}/*# sourceMappingURL=call-action.css.map */