.footer-menu {
  background-color: #01455F;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: white;
}
.footer-menu .line {
  width: 100%;
  height: 1px;
  background-color: white;
}
.footer-menu .footer-menu-item {
  display: flex;
  justify-content: space-between;
}
.footer-menu .footer-menu-item .footer-p {
  color: white;
}
.footer-menu .footer-menu-item .follow {
  display: flex;
  gap: 12px;
}
.footer-menu .footer-menu-item .follow .icon {
  color: white;
}
.footer-menu .apply-job {
  display: flex;
  gap: 12px;
}
.footer-menu .apply-job .apply {
  color: #F7941D;
}/*# sourceMappingURL=footer.css.map */