.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  color: #01455f;
  background-color: white;
  position: fixed;
  top: 68px;
  width: 100%;
  z-index: 1000;
}
.header .close-icon,
.header .menu-bar {
  display: none;
}
.header .logo img {
  width: 100px;
  height: 50px;
}
.header .nav {
  display: flex;
  align-items: center;
  gap: 24px;
}
.header .nav .team {
  display: none;
}
.header .nav .dropdown-menu {
  display: none;
}
.header .nav li .active {
  font-weight: bold;
}
.header .team-btn {
  padding: 12px 24px;
  border: 1px solid #00aeef;
  background-color: white;
  border-radius: 10px;
  color: #01455f;
}
.header .dropdown {
  position: relative;
}
.header .dropdown .dropdown-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header .dropdown .dropdown-header .dropdown-icon {
  margin-left: 5px;
  transition: transform 0.3s;
}
.header .dropdown .dropdown-header.open .dropdown-icon {
  transform: rotate(180deg);
}
.header .dropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
}
.header .dropdown .dropdown-menu li {
  margin: 0;
}
.header .dropdown .dropdown-menu li a {
  padding: 10px 8px;
  display: block;
  white-space: nowrap;
}
.header .dropdown.open .dropdown-menu {
  display: block;
}

@media screen and (max-width: 1000px) {
  .header .close-icon,
  .header .menu-bar {
    display: block;
    width: 24px;
    height: auto;
  }
  .header .menu .team {
    display: none;
  }
  .header .team .team-btn {
    display: flex;
  }
  .header .nav {
    flex-direction: column;
    align-items: flex-end;
    gap: 40px;
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: #00AEEF;
    width: 100%;
    padding: 40px;
    height: 100vh;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .header .nav .team {
    display: flex;
    background-color: white;
    border-radius: 10px;
  }
  .header .nav.open {
    display: flex;
  }
  .header .menu {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .header .dropdown .open .dropdown-menu {
    background-color: #00AEEF;
  }
  .header .dropdown .dropdown-menu li a {
    background-color: #00AEEF;
  }
}/*# sourceMappingURL=header.css.map */