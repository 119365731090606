@import '../variables.scss';

.resources-page{
    padding-block: 100px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .work-force{
        background-color: rgba(253, 234, 210, 1);
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        a{
            text-decoration: underline;
        }
    }
    .contact-number{
        display: flex;
        flex-direction: column;
        gap:20px;
        
        .contact-paylocity{
            display: flex;
            gap:24px;

       
            .employee-contacts{
                display: flex;
                flex-wrap: wrap;
                gap: 24px;
    
                .contact{
                    background-color: rgba(253, 234, 210, 1);
                        padding: 40px;
                        border-radius: 5px;
                        width:30%;
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                }

            }
            .paylocity{
                background-color: rgba(253, 234, 210, 1);
                padding: 40px;
                border-radius: 5px;
                width:50%;
                display: flex;
                flex-direction: column;
                gap: 24px;
                align-items: center;
                justify-content: center;

                a{
                    text-decoration: underline;
                }
            }
        }
       
    }
    .employee-resources{
        display: flex;
        flex-direction: column;
        gap:24px;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(1, 69, 95, 0.2);
        width: 50%;
        border-radius: 5px;
        margin:auto;
        padding: 20px;

        .policies{
            background-color: $primaryColor;
            padding: 40px;
            color: white;
            display: flex;
            flex-direction: column;
            gap: 12px;
            border-radius: 10px;
            
            ul{
                display: flex;
            flex-direction: column;
            gap:12px;
                list-style-type: disc;
                color: white;
                font-size: 24px;
            }
        }
    }
    .FQA{
        display: flex;
        flex-direction: column;
        gap:24px;

        padding: 40px;
        background-color: rgba(255, 250, 244, 1);

        h1{
            color: $secondaryColor;
        }
        .questions{
            display: flex;
            flex-direction: column;
            gap:12px;

            .question{
                display: flex;
                flex-direction: column;
                gap:12px;

                .question-icon{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .resources-page{
        .contact-number{
            .contact-paylocity{
                flex-direction: column;
                .employee-contacts{
                    
                    .contact{
                        width: 40%;
                    }
                }
                .paylocity{
                    width: 100%;
                }

            }
        }
        
    }
    
}

@media screen and (max-width: 540px) {
    .resources-page{
        .contact-number{
            .contact-paylocity{
                flex-direction: column;
                .employee-contacts{
                    
                    .contact{
                        width: 100%;
                    }
                }
                .paylocity{
                    width: 100%;
                }

            }
        }
        .employee-resources{
            
            width: 100%;
          
    
           
        }
    }
}

@media screen and (max-width: 360px) {
    .container {
        .contents {
            padding-inline: 4px;
      
           
          }
          .resources-page{
            .work-force{
                a{
                    font-size: 12px;
                }
            }
          }
    }
    
  }