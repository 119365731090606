.for-employers {
  display: flex;
  flex-direction: column;
}
.for-employers .employers {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-block: 48px;
}
.for-employers .employers .services {
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 100px;
}
.for-employers .employers .services .text {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.for-employers .employers .services .cards {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
}
.for-employers .employers .services .cards .card {
  color: #01455F;
  font-size: 16px;
}
.for-employers .employers .services .cards .card .yellow-background {
  padding: 30px 50px;
  background-color: rgba(247, 148, 29, 0.2);
  border-radius: 10px;
  font-weight: bold;
  height: 90%;
}
.for-employers .employers .services .cards .card .blue-background {
  background-color: rgba(0, 174, 239, 0.2);
  padding: 30px 50px;
  border-radius: 10px;
  font-weight: bold;
  height: 90%;
}
.for-employers .employers .onboard-process .process-img-text {
  display: grid;
  grid-template-columns: 54% 46%;
  gap: 72px;
}
.for-employers .employers .onboard-process .process-img-text .process-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.for-employers .employers .onboard-process img {
  width: 500px;
  height: 400px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.for-employers .employers .benefits-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.for-employers .employers .benefits-list .cards {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 24px;
}
.for-employers .employers .benefits-list .cards .card .yellow-background {
  padding: 28px 18px;
  background-color: rgba(247, 148, 29, 0.2);
  border-radius: 10px;
  height: 90%;
}
.for-employers .employers .benefits-list .cards .card .blue-background {
  background-color: rgba(0, 174, 239, 0.2);
  padding: 28px 18px;
  border-radius: 10px;
  height: 90%;
}
.for-employers .employers .testimonials {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.for-employers .employers .testimonials .testimonial-slider {
  overflow: hidden;
}
.for-employers .employers .testimonials .testimonial-slider .testimonial-wrapper {
  display: grid;
  transition: transform 0.5s ease;
  gap: 24px;
  grid-template-columns: 49% 49%;
}
.for-employers .employers .testimonials .testimonial-slider .testimonial-wrapper .testimonial {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 20px;
  background-color: #d9f3fd;
  border-radius: 10px;
  text-align: center;
}
@media (min-width: 950px) {
  .for-employers .employers .testimonials .testimonial-slider .testimonial-wrapper .testimonial {
    flex: 0 0 calc(33.333% - 16px);
    padding: 20px 60px;
  }
}
.for-employers .employers .testimonials .testimonial-navigation {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.for-employers .employers .testimonials .testimonial-navigation .prev-button,
.for-employers .employers .testimonials .testimonial-navigation .next-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}
.for-employers .employers .testimonials .testimonial-navigation .prev-button:disabled,
.for-employers .employers .testimonials .testimonial-navigation .next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media screen and (max-width: 1000px) {
  .for-employers .employers .services .cards {
    grid-template-columns: 48% 48%;
  }
  .for-employers .employers .onboard-process img {
    width: 400px;
  }
  .for-employers .employers .onboard-process .process-img-text {
    gap: 32px;
  }
}
@media screen and (max-width: 768px) {
  .for-employers .employers .services {
    grid-template-columns: 100%;
  }
  .for-employers .employers .services .cards {
    grid-template-columns: 48% 48%;
  }
  .for-employers .employers .onboard-process .process-img-text {
    grid-template-columns: 100%;
  }
  .for-employers .employers .onboard-process img {
    width: 100%;
  }
  .for-employers .employers .benefits-list .cards {
    grid-template-columns: 100%;
  }
  .for-employers .employers .testimonials .testimonial-slider .testimonial-wrapper .testimonial {
    padding: 20px 60px;
  }
}
@media screen and (max-width: 540px) {
  .for-employers .employers .testimonials .testimonial-slider .testimonial-wrapper {
    display: grid;
    transition: transform 0.5s ease;
    gap: 24px;
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 360px) {
  .for-employers .employers .services .cards {
    grid-template-columns: 100%;
  }
  .for-employers .employers .onboard-process img {
    width: 100%;
  }
}/*# sourceMappingURL=employers.css.map */