.for-employees {
  display: flex;
  flex-direction: column;
}
.for-employees .employees {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-block: 48px;
}
.for-employees .employees .introduction-text {
  font-size: 24px;
}
.for-employees .employees .application {
  background-color: #CCEFFC;
  padding: 60px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 30% 60%;
  justify-content: space-between;
  align-items: center;
}
.for-employees .employees .application .title {
  color: #01455F;
  font-weight: bold;
}
.for-employees .employees .application .first-applicant {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.for-employees .employees .application .first-applicant .application-btn {
  border: 1px solid #01455F;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
}
.for-employees .employees .application .first-applicant .application-btn span {
  color: #F7941D;
}
.for-employees .employees .application .contact {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.for-employees .employees .application .contact .contact-btn {
  border: 1px solid #01455F;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
}
.for-employees .employees .job-opportunities {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.for-employees .employees .job-opportunities .jobs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}
.for-employees .employees .job-opportunities .jobs .job {
  background-color: #CCEFFC;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.for-employees .employees .job-opportunities .jobs .job ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.for-employees .employees .job-opportunities .jobs .job .apply {
  color: #F7941D;
  text-decoration: underline;
}
.for-employees .employees .job-opportunities .jobs .job .job-title {
  color: #01455F;
  font-weight: bold;
}
.for-employees .employees .notes {
  color: #01455F;
  display: flex;
  gap: 12px;
  flex-direction: column;
}
.for-employees .employees .employees-benefits {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.for-employees .employees .employees-benefits .cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.for-employees .employees .employees-benefits .cards .card {
  border: 1px solid rgba(1, 69, 95, 0.1);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 32%;
}
.for-employees .employees .employees-benefits .cards .card .icon {
  background-color: #01455F;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

@media screen and (max-width: 600px) {
  .for-employees .employees .application {
    grid-template-columns: 100%;
    gap: 48px;
  }
  .for-employees .employees .job-opportunities .jobs {
    grid-template-columns: 1fr;
  }
  .for-employees .employees .employees-benefits .cards {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .for-employees .employees .employees-benefits .cards .card {
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .for-employees .employees .employees-benefits .cards {
    display: grid;
    grid-template-columns: 100%;
  }
}/*# sourceMappingURL=employees.css.map */