@import "../variables";
.team-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 42px;

  .team-members {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 24px;
   
    .team-member {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 12px;
      overflow: hidden;
      border-radius: 10px;

      &:hover{
        .card-overlay{
          top: 0;
          
         
          transition: .5s ease;
        }
      }


      .card-overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        right: 0;
        left: 0;
        top: 700px;
        bottom: 0;
        background: $primaryColor;
        padding: 20px;
        transition: .5s ease;





        p, .title-overlay, h3{
          color: white;
        }
      }
      .team-member-image {
        width: 100%;
        img {
          width: 100%;
          height: 350px;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .team-member-info {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
          color: $secondaryColor;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .team-container {
    .team-members {
      grid-template-columns: 50% 50%;
      .team-member {
        .team-member-image {
          img {
            width: 95%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .team-container {
    .team-members {
      grid-template-columns: 100%;
      .team-member {
        .team-member-image {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
