.about-us {
    .page-header {
      position: relative;
  
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
      }
  
      .page-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-inline: 100px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.64);
        height: 60vh;
        inset: 0;
  
        .page-text {
          gap: 24px;
          color: white;
          width: fit-content;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-us .page-header {
      .page-content {
        padding-inline: 20px;
      }
    }
  }
  