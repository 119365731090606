.page-header-p {
  color: white;
  width: 50%;
  font-size: 24px;
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 42px;
}
.about-container .about-contents {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 20px;
}
.about-container .about-contents .company-history {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
}
.about-container .about-contents .values {
  padding: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.about-container .about-contents .values .values-img {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
  align-items: center;
}
.about-container .about-contents .values .values-img .values-list {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
  justify-content: center;
}
.about-container .about-contents .values .values-img .values-list .value {
  color: #01455f;
  font-size: 16px;
}
.about-container .about-contents .values .values-img .values-list .yellow-background {
  padding: 18px;
  background-color: rgba(247, 148, 29, 0.2);
  border-radius: 10px;
}
.about-container .about-contents .values .values-img .values-list .blue-background {
  background-color: rgba(0, 174, 239, 0.2);
  padding: 18px;
  border-radius: 10px;
}
.about-container .about-contents .values .values-img img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.about-container .mission {
  background-color: #01455f;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 12px;
}
.about-container .mission p {
  color: white;
}
.about-container .mission h1 {
  color: #00aeef;
}
.about-container .mission img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-container .mission .text {
  padding: 100px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.about-container .partners {
  padding-block: 50px;
  padding-inline: 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.about-container .partners .partners-logo {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

@media screen and (max-width: 1000px) {
  .page-header-p {
    color: white;
    width: 100%;
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .about-container .about-contents .company-history {
    grid-template-columns: 100%;
  }
  .about-container .about-contents .values .values-img {
    grid-template-columns: 100%;
  }
  .about-container .about-contents .values .values-img .values-list {
    grid-template-columns: 100%;
  }
  .about-container .mission {
    grid-template-columns: 100%;
    gap: 12px;
  }
  .about-container .mission .text {
    padding: 30px 20px;
  }
  .about-container .partners {
    padding-inline: 20px;
  }
  .page-header-p {
    color: white;
    width: 100%;
    font-size: 18px;
  }
}
@media screen and (max-width: 360px) {
  .about-container .partners .partners-logo img {
    width: 100%;
    height: auto;
  }
}/*# sourceMappingURL=about.css.map */