@import "../variables";

.container {
  .contacts {
    background-color: $secondaryColor;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 100px;
    color: $primaryColor;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    .contact-info {
      display: flex;
      gap: 24px;

      .location, .email, .phone{
        a{
          display: flex;
          gap: 12px;
          align-items: center;
        }
      }
      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}
@media screen and (max-width:1000px) {
  .container{
    .contacts{
      padding: 24px 32px;
    }
  }
}
@media screen and (max-width: 930px) {
  .container {
    .contacts {
      padding: 24px 20px;
      .contact-info {
        div {
          span {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container .contacts {
    padding: 24px 40px;
  }
}
