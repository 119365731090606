.contact-us .contact-page {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-block: 42px;
}
.contact-us .contact-page .contact-address {
  display: grid;
  grid-template-columns: 28% 66%;
  gap: 70px;
}
.contact-us .contact-page .contact-address .address-cards {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.contact-us .contact-page .contact-address .address-cards p, .contact-us .contact-page .contact-address .address-cards h3 {
  color: white;
}
.contact-us .contact-page .contact-address .address-cards .icon {
  color: #F7941D;
}
.contact-us .contact-page .contact-address .address-cards .card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  border-radius: 10px;
  background-color: #01455F;
  padding: 60px 20px;
}
.contact-us .contact-page .contact-address .address-cards .card .icon-text {
  display: flex;
  gap: 24px;
  align-items: center;
}
.contact-us .contact-page .contact-address .contact-form form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contact-us .contact-page .contact-address .contact-form form .form-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contact-us .contact-page .contact-address .contact-form form .form-group .orange-btn {
  width: -moz-fit-content;
  width: fit-content;
}
.contact-us .contact-page .contact-address .contact-form form .half-input {
  display: flex;
  gap: 12px;
}
.contact-us .contact-page .contact-address .contact-form form .half-input .form-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

@media screen and (max-width: 810px) {
  .contact-us .contact-page .contact-address {
    display: grid;
    grid-template-columns: 100%;
  }
}/*# sourceMappingURL=contact.css.map */