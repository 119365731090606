@import '../variables.scss';

.hiring {

    .hiring-page {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding-block: 100px;

        .expertise {
            p,h3{
                color: white;
            }
            .expertise-img {
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 24px;

                img {
                    width: 100%;
                    height: 600px;
                    object-fit: cover;
                    border-radius: 10px;
                }

                .exertise-list {
                    background-color: $primaryColor;
                    padding: 40px;
                    border-radius: 10px;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .text-icon {
                        display: flex;
                        gap: 24px;
                        align-items: center;

                        .text {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }

                        .icon {
                            color: #F7941D;
                        }
                    }
                }
            }


        }

        .employee-benefits {
            display: flex;
            flex-direction: column;
            gap: 24px;
            color: $primaryColor;

            .text-section {
                display: grid;
                grid-template-columns: 50% 50%;
                gap: 100px;
                width: 100%;

                img {
                    width: 100%;
                    height: 300px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                .img-text {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                }
            }

            .benefits {
                display: flex;
                flex-direction: column;
                gap: 18px;

                .benefit {
                    display: flex;
                    gap: 12px;

                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .hiring{
        .hiring-page {
            .expertise {
                .expertise-img {
                    grid-template-columns: 48% 48%;
                    
                
                }
    }
    .employee-benefits{
        .text-section {
            display: grid;
            grid-template-columns: 42% 42%;
            gap: 94px;
            width: 100%;
          }
    }
}
}
    
}


@media screen and (max-width: 800px) {
    .hiring {
        .hiring-page {
            .expertise {
                .expertise-img {
                    grid-template-columns: 100%;
                    img {
                        height: 300px;
                    }
    
                   
                }
    
    
            }

            .employee-benefits {
                .text-section {
                    grid-template-columns: 100%;
                }

                .benefits {
                    grid-template-columns: 100%;
                }
            }
        }
    }

}